import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router";
import { clearLogoutMsg } from "../../actions/logoutAction";
import { useDispatch } from "react-redux";
import {openNav} from "../../../src/assets/js/customNav"
import {closeNav} from "../../../src/assets/js/customNav"
import headerAction from "../../actions/headerAction";

const Header = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [userData, setUserData] = useState("");
 
    useEffect(() => {
        const user = JSON.parse(localStorage.getItem("userData"));
        if (user) {
            setUserData(user.data.data.user);
        }
    }, []);

    const handleLogout = () => {
        localStorage.removeItem("token");
        localStorage.removeItem("userData");
        localStorage.removeItem("FCM_TOKEN");
        localStorage.removeItem("FIREBASE_ACCESS_TOKEN");
        navigate("/home");
        dispatch(clearLogoutMsg());
    };
    return (
        <>
            <div className="wrapper">
                <header className="header fadeInDown wow">
                    <div className="container">
                        <nav className="navbar">
                            {userData && userData.email_verified_at != null ? (
                                <div className="container">
                                    <Link className="navbar-brand desktop-brand" onClick={()=> dispatch(headerAction(true))} to="/consumer-home">
                                        <img src="/img/logo.svg" alt="ozgigs" />
                                    </Link>
                                    <div id="mySidenav" className="sidenav">
                                        <span className="closebtn"  onClick={closeNav} >&times;</span>
                                        <Link to="/consumer-home" onClick={()=> dispatch(headerAction(true))} className="navbar-brand">
                                            <img src="/img/logo.svg" alt="ozgigs" />
                                        </Link>
                                        <ul>
                                            <li>
                                                <Link target="_blank" to="/faq#AboutUs" className="nav-link">
                                                    <img src="/img/Search.svg" alt="" />
                                                    About Us
                                                </Link>
                                            </li>
                                            <li>
                                                <Link target="_blank" to="/faq#Support" className="nav-link">
                                                    <img src="/img/Search.svg" alt="" />
                                                    Contact
                                                </Link>
                                            </li>
                                            <li>
                                                <Link target="_blank" to="/faq" className="nav-link">
                                                    <img src="/img/Circle_Warning.svg" alt="" />
                                                    Help
                                                </Link>
                                            </li>
                                        </ul>

                                    </div>
                                    <span className="menu-btn" onClick={openNav} >&#9776;</span>
                                    <div className="signup-signin-sec">
                                        {/* <Link to="/consumer-home" title="Home" className="btn btn_normal">Home</Link> */}
                                        <Link to="/consumer-dashboard" title="Dashboard" className="btn btn_normal">Dashboard</Link>
                                        <a href="#" onClick={() => { handleLogout(); }} className="btn"> Logout </a>
                                    </div>
                                </div>
                            ) : (
                                <div className="container">
                                    <Link className="navbar-brand desktop-brand" onClick={()=> dispatch(headerAction(true))} to="/home">
                                        <img src="/img/logo.svg" alt="ozgigs" />
                                    </Link>
                                    <div id="mySidenav" className="sidenav">
                                        <span className="closebtn"  onClick={closeNav} >&times;</span>
                                        <Link className="navbar-brand" onClick={()=> dispatch(headerAction(true))} to="/home">
                                            <img src="/img/logo.svg" alt="ozgigs" />
                                        </Link>
                                        <ul>
                                            <li>
                                                <Link target="_blank" to="/faq#AboutUs" className="nav-link">
                                                    <img src="/img/Search.svg" alt="" />
                                                    About Us
                                                </Link>
                                            </li>
                                            <li>
                                                <Link target="_blank" to="/faq#Support" className="nav-link">
                                                    <img src="/img/Search.svg" alt="" />
                                                    Contact
                                                </Link>
                                            </li>
                                            <li>
                                                <Link target="_blank" to="/faq" className="nav-link">
                                                    <img src="/img/Circle_Warning.svg" alt="" />
                                                    Help
                                                </Link>
                                            </li>
                                        </ul>

                                    </div>
                                    <span className="menu-btn"  onClick={openNav} >&#9776;</span>
                                    <div className="signup-signin-sec">
                                        <Link to="/about-us" className={ window.location.href.indexOf("about-us") > -1 ? "btn" : "btn btn_normal"}> About Us </Link>
                                        <Link to="/signup" className={ window.location.href.indexOf("signup") > -1 ? "btn" : "btn btn_normal"}> Sign Up </Link>
                                        <Link to="/login" className={ window.location.href.indexOf("login") > -1 ? "btn" : "btn btn_normal"}> Sign In </Link>
                                    </div>
                                </div>
                            )}
                        </nav>
                    </div>
                </header>
            </div>
        </>
    );
};

export default Header;
